import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { cloneDeep, findIndex, filter } from "lodash";
import { APIService } from "src/app/services/api.service";
import { Helper } from "src/app/shared/helper";
import { OktaAuthStateService } from "@okta/okta-angular";
import { AuthState } from "@okta/okta-auth-js";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-session-details",
  templateUrl: "./session-details.component.html",
  styleUrls: ["./session-details.component.scss"],
})
export class SessionDetailsComponent implements OnInit {
  public isPutMeOnWaitingList = false;
  public isSubmitted = false;
  private isCourseLevelEnabled = false;
  public isGroupEnabled = false;
  public isCourseComplete = false;
  public isChangeMade = false;
  private originalOutput;
  private toastRef;

  @BlockUI() blockUI: NgBlockUI;
  public courseList = [];
  public originalCourseList = [];

  private courseApiResponse: any;
  private tempCourseApiResponse: any;

  // Login API Response
  private accessToken = "";
  private loggedInUserEmail = "";
  private courseCategory = "";

  public isAssistant = false;
  public selectedEmployeeEmail = "";
  public selectedEmployeeId = "";
  public employeeList = [];

  private initialLoadMessage = "";

  private isFirstLoading = true;

  constructor(
    private _apiService: APIService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _oktaAuthStateService: OktaAuthStateService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    // Okta Authentication
    this._oktaAuthStateService.authState$.subscribe((authState: AuthState) => {
      this.loggedInUserEmail = authState.idToken && authState.idToken.claims.email ? authState.idToken.claims.email : "";
      this.selectedEmployeeEmail = this.loggedInUserEmail;
    });

    this.route.queryParams.subscribe((params) => {
      this.courseCategory = params["coursename"];
    });
  }

  // Onload Function
  ngOnInit(): void {
    this.loginAPIService();
  }

  changeUser(event) {
    this.selectedEmployeeEmail = event.target.value;
    this.getAllEmployeeSessions(this.selectedEmployeeEmail, this.courseCategory);
  }

  addToWaitingList() {
    this.blockUI.start("Loading..."); // Start blocking
    setTimeout(() => {
      this.blockUI.stop(); // Stop blocking
      Swal.fire("Added!", "Successfully added to the waiting list!", "success");
    }, 1500);
  }

  async saveSessionDetails() {
    this.blockUI.start("Loading..."); // Start blocking
    const output = {
      employeeId: this.selectedEmployeeId,
      courses: [],
    };
    let tableCoursename = [];
    let tableStatus = [];
    let tableSession = [];
    const tempOriginalOutput = cloneDeep(this.originalOutput);
    for (const inputCourse of this.courseList) {
      const selectedSessions = inputCourse.courseSessions.filter((s) => s.isSelected);
      const findTempOutput = tempOriginalOutput.courses.filter((obj) => obj.courseId === inputCourse.courseId);
      const tempOutput = findTempOutput.length > 0 ? findTempOutput[0] : null;
      if (selectedSessions.length > 0 && !selectedSessions[0].isPutMeOnWaitingList) {
        let outputObj = {};
        if (inputCourse.employeeCourseSession) {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: cloneDeep(inputCourse.employeeCourseSession),
            waitList: false,
          };
          outputObj["employeeCourseSession"]["selectedCourseSessionId"] = selectedSessions[0].sessionID;
          if (tempOutput && this.isEqual(outputObj, tempOutput)) {
            outputObj = null;
          } else {
            output.courses.push(outputObj);
            if (inputCourse.employeeCourseSession.courseCompletionStatus === "Awaiting Session") {
              const tempCourseList = cloneDeep(this.courseList);
              const tempSelSessions = filter(tempCourseList, { courseSessions: [{ isSelected: true }] });
              if (tempSelSessions.length > 0) {
                tableCoursename.push(inputCourse.courseName);
                tableStatus.push("Session Booked");
                tableSession.push(selectedSessions[0].updatedCourseSessionName);
              } else {
                tableCoursename.push(inputCourse.courseName);
                tableStatus.push("Awaiting Session");
                tableSession.push("N/A");
              }
            } else {
              const previouslySelectedSession = inputCourse.courseSessions.filter((s) => s.sessionID === inputCourse.employeeCourseSession?.selectedCourseSessionId);
              tableCoursename.push(inputCourse.courseName);
              tableStatus.push(previouslySelectedSession[0].sessionID === selectedSessions[0].sessionID ? "Session Booked" : "Session Updated");
              tableSession.push(
                `From '${previouslySelectedSession[0].sessionID === selectedSessions[0].sessionID ? "N/A" : previouslySelectedSession[0].updatedCourseSessionName}' to '${
                  selectedSessions[0].updatedCourseSessionName
                }'`
              );
            }
          }
        } else {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: {
              employeeCourseSessionId: "",
              selectedCourseSessionId: selectedSessions[0].sessionID,
              cancel: false,
              cancelledReason: "",
            },
            waitList: false,
          };
          if (tempOutput && this.isEqual(outputObj, tempOutput)) {
            outputObj = null;
          } else {
            output.courses.push(outputObj);
            tableCoursename.push(inputCourse.courseName);
            tableStatus.push("Session Booked");
            tableSession.push(selectedSessions[0].updatedCourseSessionName);
          }
        }
      } else if (selectedSessions.length === 0 && !inputCourse.isPutMeOnWaitingList && inputCourse.employeeCourseSession?.selectedCourseSessionId) {
        let outputObj = {
          courseId: inputCourse.courseId,
          employeeCourseSession: cloneDeep(inputCourse.employeeCourseSession),
          waitList: false,
        };
        outputObj["employeeCourseSession"]["cancel"] = true;
        outputObj["employeeCourseSession"]["cancelledReason"] = inputCourse.cancelledReason;
        if (tempOutput && this.isEqual(outputObj, tempOutput)) {
          outputObj = null;
        } else {
          output.courses.push(outputObj);
          tableCoursename.push(inputCourse.courseName);
          tableStatus.push("Session Cancelled");
          tableSession.push("N/A");
        }
      } else if (inputCourse.isPutMeOnWaitingList) {
        let outputObj = {};
        if (inputCourse.employeeCourseSession) {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: cloneDeep(inputCourse.employeeCourseSession),
            waitList: true,
          };
          outputObj["employeeCourseSession"]["selectedCourseSessionId"] = "";
        } else {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: {
              employeeCourseSessionId: "",
              selectedCourseSessionId: "",
              cancel: false,
              cancelledReason: "",
            },
            waitList: true,
          };
        }
        if (tempOutput && this.isEqual(outputObj, tempOutput)) {
          outputObj = null;
        } else {
          output.courses.push(outputObj);
          tableCoursename.push(inputCourse.courseName);
          tableStatus.push("Added to Waiting List");
          tableSession.push("N/A");
        }
      }
    }

    const tempSelectedCourse = cloneDeep(output);
    const selectedCourses = tempSelectedCourse.courses.filter((s) => s.employeeCourseSession?.selectedCourseSessionId);
    const bookedCourses = this.courseList.filter((s) => s.employeeCourseSession?.courseCompletionStatus["Session Booked"]);
    let isAllCoursesBooked = false;
    if (bookedCourses.length === this.originalCourseList.length) {
      isAllCoursesBooked = true;
    }
    if (selectedCourses.length === this.originalCourseList.length) {
      isAllCoursesBooked = true;
    }

    const tempCourse = cloneDeep(output);
    const cancelledCourses = tempCourse.courses.filter((s) => s.employeeCourseSession?.cancel);

    if (this.isGroupEnabled && !this.isPutMeOnWaitingList && !isAllCoursesBooked && this.courseList.length !== cancelledCourses.length) {
      this.blockUI.stop(); // Stop blocking
      Swal.fire("Info", "Please select session in all the courses.", "info");
      return;
    }

    if (this.isGroupEnabled && cancelledCourses.length > 0 && this.courseList.length !== cancelledCourses.length) {
      this.blockUI.stop(); // Stop blocking
      Swal.fire("Info", "Please cancel session in all the courses.", "info");
      return;
    }

    if (output.courses.length !== 0) {
      this.removeToast();
      this.isChangeMade = false;
      const apiResponse = await this._apiService.updateSessions(output, this.accessToken);
      await this.getAllEmployeeSessions(this.selectedEmployeeEmail, this.courseCategory);
      this.blockUI.stop(); // Stop blocking
      if (apiResponse["isSuccess"]) {
        this.removeToast();
        this.isChangeMade = false;
        let result = [];
        for (let k = 0; k < tableCoursename.length; k++) {
          result.push("<tr>");
          result.push(`<td>${tableCoursename[k]}</td>`);
          result.push(`<td>${tableStatus[k]}</td>`);
          result.push(`<td>${tableSession[k]}</td>`);
          result.push("</tr>");
        }
        setTimeout(() => {
          Swal.fire({
            title: "Successfully Updated",
            icon: "success",
            width: "800px",
            html: `<table id="table" style="width:100%" class="popup-table-text">
              <thead>
                  <tr>
                      <th style="width:30%">Course Name</th>
                      <th style="width:20%">Status</th>
                      <th style="width:50%">Session</th>
                  </tr>
              </thead>
              <tbody>
                  ${result.join("\n")}
      </tbody>
      </table>`,
          });
        }, 550);
      } else {
        setTimeout(() => {
          this.checkForChanges();
        }, 500);
        setTimeout(() => {
          Swal.fire("Error!", apiResponse["responseMessage"], "error");
        }, 550);
      }
    } else {
      this.blockUI.stop(); // Stop blocking
      Swal.fire("Info!", "Please select atleast one session.", "info");
    }
  }

  cancelSession(courseIndex, sessionIndex, inputValue, isUpdateCancelReason) {
    Swal.fire({
      title: isUpdateCancelReason ? "Update Reason" : "Cancel Session!",
      text: isUpdateCancelReason
        ? "Please update the reason for the cancellation."
        : "Please note that CVC Training Team will be informed about your training session Cancellation, please mention the reason below.",
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        return !value && "Reason for the cancellation is mandatory.";
      },
      inputValue: inputValue ? inputValue : "",
    }).then((result) => {
      if (result.value) {
        if (this.isGroupEnabled) {
          for (let i = 0; i < this.courseList.length; i++) {
            for (let j = 0; j < this.courseList[i].courseSessions.length; j++) {
              if (this.courseList[i].courseSessions[j].isSelected && this.courseList[i].employeeCourseSession?.selectedCourseSessionId === this.courseList[i].courseSessions[j].sessionID) {
                this.courseList[i].courseSessions[j].isCancelled = true;
                this.courseList[i].courseSessions[j].isSelected = false;
              } else if (this.courseList[i].employeeCourseSession?.selectedCourseSessionId === this.courseList[i].courseSessions[j].sessionID) {
                this.deselectAllSessionsInCourse(i);
                this.courseList[i].courseSessions[j].isCancelled = true;
                this.courseList[i].courseSessions[j].isSelected = false;
              }
            }
            this.courseList[i].cancelledReason = result.value;
          }
        } else {
          this.courseList[courseIndex].cancelledReason = result.value;
        }
      } else {
        this.courseList[courseIndex].courseSessions[sessionIndex].isSelected = true;
        this.courseList[courseIndex].courseSessions[sessionIndex].isCancelled = false;
        this.courseList[courseIndex].cancelledReason = "";

        if (this.isCourseLevelEnabled) {
          const nextCourseIndex = courseIndex + 1;
          if (nextCourseIndex < this.courseList.length) {
            for (let j = 0; j < this.courseList[nextCourseIndex].courseSessions.length; j++) {
              if (
                !this.courseList[nextCourseIndex].isPutMeOnWaitingList &&
                this.courseList[nextCourseIndex].isDateValidationEnabled &&
                this.courseList[nextCourseIndex].courseSessions[j].sessionStartDateTime.isSameOrAfter(this.courseList[courseIndex].courseSessions[sessionIndex].sessionEndDateTime)
              ) {
                // Enable
                this.courseList[nextCourseIndex].courseSessions[j].disabled = false;
              } else {
                // Disable
                this.courseList[nextCourseIndex].courseSessions[j].disabled = true;
                if (!this.courseList[nextCourseIndex].isAlreadyBookedCourse) {
                  this.courseList[nextCourseIndex].courseSessions[j].isSelected = false;
                }
              }
            }
          }
        }
      }
      setTimeout(() => {
        this.checkForChanges();
      }, 500);
    });
  }

  onToggleChange(selectedCourse, selectedSession) {
    const selectedCourseLevel = selectedCourse.courseLevel;
    const tempCourseList = cloneDeep(this.courseList);
    const courseIndex = findIndex(tempCourseList, { courseId: selectedCourse.courseId });
    const sessionIndex = findIndex(tempCourseList[courseIndex].courseSessions, { sessionID: selectedSession.sessionID });
    if (this.isCourseLevelEnabled) {
      const nextCourse = courseIndex + 1;
      if (nextCourse < tempCourseList.length) {
        const oneLevelAfterSelectedSession = tempCourseList[nextCourse].courseSessions.filter((s) => s.isSelected);
        if (oneLevelAfterSelectedSession.length !== 0 && selectedCourse.employeeCourseSession?.selectedCourseSessionId === selectedSession.sessionID && selectedSession.isSelected) {
          Swal.fire("Info!", "Please cancel the session in the next course before cancelling the current one.", "info");
          setTimeout(() => {
            this.courseList[courseIndex].courseSessions[sessionIndex].isSelected = true;
          }, 100);
          return;
        }
        const nextCourseSelectedSession = tempCourseList[nextCourse].courseSessions.filter((s) => s.isSelected);
        if (nextCourseSelectedSession.length > 0 && selectedSession.sessionStartDateTime.isSameOrAfter(nextCourseSelectedSession[0].sessionStartDateTime)) {
          setTimeout(() => {
            Swal.fire("Info!", "Next course session time is colliding with the current selection. Please update the next course session before updating the current session time.", "info");
            this.courseList[courseIndex].courseSessions[sessionIndex].isSelected = false;
          }, 100);
          return;
        }
      }
    }

    this.courseList = this.courseList.length > 0 ? this.courseList.sort((a, b) => a.courseLevel - b.courseLevel) : [];
    for (let i = 0; i < this.courseList.length; i++) {
      for (let j = 0; j < this.courseList[i].courseSessions.length; j++) {
        if (
          !selectedSession.isSelected &&
          this.isCourseLevelEnabled &&
          this.courseList[i].courseLevel > selectedCourseLevel &&
          !this.courseList[i].isPutMeOnWaitingList &&
          this.courseList[i].courseLevel - selectedCourseLevel <= 1
        ) {
          if (this.courseList[i].isDateValidationEnabled && this.courseList[i].courseSessions[j].sessionStartDateTime.isSameOrAfter(selectedSession.sessionEndDateTime)) {
            // Enable
            this.courseList[i].courseSessions[j].disabled = false;
          } else {
            // Disable
            this.courseList[i].courseSessions[j].disabled = true;
            if (!this.courseList[i].isAlreadyBookedCourse) {
              this.courseList[i].courseSessions[j].isSelected = false;
            }
          }
        } else if (!selectedSession.isSelected && this.isCourseLevelEnabled && this.courseList[i].courseLevel - selectedCourseLevel > 1 && !this.courseList[i].isPutMeOnWaitingList) {
          this.courseList[i].courseSessions[j].disabled = true;
          if (!this.courseList[i].isAlreadyBookedCourse) this.courseList[i].courseSessions[j].isSelected = false;
        } else if (selectedSession.isSelected && this.isCourseLevelEnabled && this.courseList[i].courseLevel > selectedCourseLevel && !this.courseList[i].isPutMeOnWaitingList) {
          this.courseList[i].courseSessions[j].disabled = true;
          if (!this.courseList[i].isAlreadyBookedCourse) this.courseList[i].courseSessions[j].isSelected = false;
        } else if (
          !selectedSession.isSelected &&
          this.isCourseLevelEnabled &&
          this.courseList[i].courseLevel - selectedCourseLevel > 1 &&
          !this.courseList[i].isPutMeOnWaitingList &&
          this.courseList[i].courseSessions[j].sessionStartDateTime.isBefore(selectedSession.sessionEndDateTime)
        ) {
          this.courseList[i].courseSessions[j].disabled = true;
          if (!this.courseList[i].isAlreadyBookedCourse) this.courseList[i].courseSessions[j].isSelected = false;
        } else if (
          selectedSession.isSelected &&
          this.isCourseLevelEnabled &&
          this.courseList[i].courseLevel > selectedCourseLevel &&
          !this.courseList[i].isPutMeOnWaitingList &&
          this.courseList[i].courseSessions[j].sessionStartDateTime.isBefore(selectedSession.sessionEndDateTime)
        ) {
          this.courseList[i].courseSessions[j].disabled = true;
          if (!this.courseList[i].isAlreadyBookedCourse) this.courseList[i].courseSessions[j].isSelected = false;
        }

        // If a toggle is on, switch off other toggles in the same course.
        if (!selectedSession.isSelected && selectedSession.sessionID !== this.courseList[i].courseSessions[j].sessionID && this.courseList[i].courseId === selectedCourse.courseId) {
          this.courseList[i].courseSessions[j].isSelected = false;
        }
      }
      const selectedSessions = selectedCourse.courseSessions.filter((s) => s.isSelected);
      if (selectedSessions.length === 1 && this.courseList[i].courseId === selectedCourse.courseId && selectedCourse.employeeCourseSession?.selectedCourseSessionId) {
        const sessionIndex = findIndex(selectedCourse.courseSessions, { sessionID: selectedCourse.employeeCourseSession?.selectedCourseSessionId });
        if (!this.courseList[i].courseSessions[sessionIndex].isCancelled && !this.courseList[i].courseSessions[sessionIndex].disabled)
          this.courseList[i].courseSessions[sessionIndex].isSelected = true;
      }
    }

    if (selectedSession.isSelected && selectedCourse.employeeCourseSession?.selectedCourseSessionId === selectedSession.sessionID) {
      this.courseList[courseIndex].courseSessions[sessionIndex].isCancelled = true;
      this.cancelSession(courseIndex, sessionIndex, null, false);
    } else if (!selectedSession.isSelected && selectedCourse.employeeCourseSession?.selectedCourseSessionId === selectedSession.sessionID) {
      this.courseList[courseIndex].courseSessions[sessionIndex].isCancelled = false;
      this.courseList[courseIndex].cancelledReason = "";
      setTimeout(() => {
        this.checkForChanges();
      }, 500);
    } else {
      setTimeout(() => {
        this.checkForChanges();
      }, 500);
    }
  }

  onSessionWaitingListToggleChange(value, courseIndex) {
    if (value) {
      this.courseList[courseIndex].isPutMeOnWaitingList = true;
      const tempCourseList = cloneDeep(this.courseList);
      if (this.isCourseLevelEnabled) {
        const nextCourse = courseIndex + 1;
        if (nextCourse < tempCourseList.length) {
          const oneLevelAfterSelectedSession = tempCourseList[nextCourse].courseSessions.filter((s) => s.isSelected);
          if (oneLevelAfterSelectedSession.length !== 0) {
            Swal.fire("Info!", "Please cancel the session in the next course before making this as waiting list", "info");
            setTimeout(() => {
              this.courseList[courseIndex].isPutMeOnWaitingList = false;
            }, 100);
          } else {
            this.disableAllSessionsInCourse(courseIndex);
          }
        } else {
          this.disableAllSessionsInCourse(courseIndex);
        }
      } else {
        this.disableAllSessionsInCourse(courseIndex);
      }
    } else {
      const tempCourseList = cloneDeep(this.courseList);
      const previousCourse = courseIndex - 1;
      if (previousCourse >= 0) {
        const oneLevelBeforeSelectedSession = tempCourseList[previousCourse].courseSessions.filter((s) => s.isSelected);
        if (this.isCourseLevelEnabled && oneLevelBeforeSelectedSession.length === 0) {
          Swal.fire("Info!", "Please select any session in the previous course before disabling the waiting list.", "info");
          setTimeout(() => {
            this.courseList[courseIndex].isPutMeOnWaitingList = true;
          }, 100);
          return;
        }
      }
      this.courseList[courseIndex].isPutMeOnWaitingList = false;
      const i = courseIndex;
      for (let j = 0; j < this.courseList[i].courseSessions.length; j++) {
        if (this.isCourseLevelEnabled && i > 0 && !this.courseList[i].isAlreadyBookedCourse) {
          this.courseList[i].courseSessions[j].disabled = true;
        } else {
          this.courseList[i].courseSessions[j].disabled = false;
        }
        let previousBookedCourse = null;
        let previousBookedSession = null;
        let previousBookedCourseIndex = null;
        if (this.courseList[i].isAlreadyBookedCourse) {
          if (
            this.courseList[i].employeeCourseSession &&
            this.courseList[i].employeeCourseSession.employeeCourseSessionId &&
            this.courseList[i].employeeCourseSession.courseCompletionStatus !== "Awaiting Session" &&
            this.courseList[i].courseSessions[j].sessionID === this.courseList[i].employeeCourseSession.selectedCourseSessionId
          ) {
            previousBookedCourse = this.courseList[i];
            previousBookedSession = this.courseList[i].courseSessions[j];
            previousBookedCourseIndex = i;

            if (!this.courseList[i].courseSessions[j].isCancelled) this.courseList[i].courseSessions[j].isSelected = true;
          } else {
            this.courseList[i].courseSessions[j].isSelected = false;
          }
        }

        if (previousBookedCourse && i - previousBookedCourseIndex === 1) {
          if (this.courseList[i].isDateValidationEnabled) {
            if (this.courseList[i].courseSessions[j].sessionStartDateTime.isSameOrAfter(previousBookedSession.sessionEndDateTime)) {
              // Enable
              this.courseList[i].courseSessions[j].disabled = false;
            } else {
              // Disable
              this.courseList[i].courseSessions[j].disabled = true;
            }
          } else {
            // Enable
            this.courseList[i].courseSessions[j].disabled = false;
          }
        }

        if (i > 0) {
          const oneLevelBeforeSelectedSession = this.courseList[i - 1].courseSessions.filter((s) => s.isSelected);
          if (oneLevelBeforeSelectedSession.length > 0) {
            if (this.courseList[i].isDateValidationEnabled) {
              if (this.courseList[i].courseSessions[j].sessionStartDateTime.isSameOrAfter(oneLevelBeforeSelectedSession[0].sessionEndDateTime)) {
                // Enable
                this.courseList[i].courseSessions[j].disabled = false;
              } else {
                // Disable
                this.courseList[i].courseSessions[j].disabled = true;
                this.courseList[i].courseSessions[j].isSelected = false;
              }
            } else {
              // Enable
              this.courseList[i].courseSessions[j].disabled = false;
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.checkForChanges();
    }, 500);
  }

  disableAllSessionsInCourse(courseIndex) {
    for (let j = 0; j < this.courseList[courseIndex].courseSessions.length; j++) {
      // Disable
      this.courseList[courseIndex].courseSessions[j].disabled = true;
      this.courseList[courseIndex].courseSessions[j].isSelected = false;
    }
  }

  deselectAllSessionsInCourse(courseIndex) {
    for (let j = 0; j < this.courseList[courseIndex].courseSessions.length; j++) {
      // Disable
      this.courseList[courseIndex].courseSessions[j].isSelected = false;
    }
  }

  onWaitingListToggleChange(value) {
    if (value) {
      for (let i = 0; i < this.courseList.length; i++) {
        this.courseList[i].isPutMeOnWaitingList = true;
        for (let j = 0; j < this.courseList[i].courseSessions.length; j++) {
          // Disable
          this.courseList[i].courseSessions[j].disabled = true;
          this.courseList[i].courseSessions[j].isSelected = false;
        }
      }
    } else {
      for (let i = 0; i < this.courseList.length; i++) {
        this.courseList[i].isPutMeOnWaitingList = false;
        for (let j = 0; j < this.courseList[i].courseSessions.length; j++) {
          if (this.isCourseLevelEnabled && i > 0 && !this.courseList[i].isAlreadyBookedCourse) {
            this.courseList[i].courseSessions[j].disabled = true;
          } else {
            this.courseList[i].courseSessions[j].disabled = false;
          }
          let previousBookedCourse = null;
          let previousBookedSession = null;
          let previousBookedCourseIndex = null;
          if (this.courseList[i].isAlreadyBookedCourse) {
            if (
              this.courseList[i].employeeCourseSession &&
              this.courseList[i].employeeCourseSession.employeeCourseSessionId &&
              this.courseList[i].employeeCourseSession.courseCompletionStatus !== "Awaiting Session" &&
              this.courseList[i].courseSessions[j].sessionID === this.courseList[i].employeeCourseSession.selectedCourseSessionId
            ) {
              previousBookedCourse = this.courseList[i];
              previousBookedSession = this.courseList[i].courseSessions[j];
              previousBookedCourseIndex = i;

              if (!this.courseList[i].courseSessions[j].isCancelled) this.courseList[i].courseSessions[j].isSelected = true;
            } else {
              this.courseList[i].courseSessions[j].isSelected = false;
            }
          }

          if (previousBookedCourse && i - previousBookedCourseIndex === 1) {
            if (this.courseList[i].isDateValidationEnabled) {
              if (this.courseList[i].courseSessions[j].sessionStartDateTime.isSameOrAfter(previousBookedSession.sessionEndDateTime)) {
                // Enable
                this.courseList[i].courseSessions[j].disabled = false;
              } else {
                // Disable
                this.courseList[i].courseSessions[j].disabled = true;
              }
            } else {
              // Enable
              this.courseList[i].courseSessions[j].disabled = false;
            }
          }

          if (i > 0) {
            const oneLevelBeforeSelectedSession = this.courseList[i - 1].courseSessions.filter((s) => s.isSelected);
            if (oneLevelBeforeSelectedSession.length > 0) {
              if (this.courseList[i].isDateValidationEnabled) {
                if (this.courseList[i].courseSessions[j].sessionStartDateTime.isSameOrAfter(oneLevelBeforeSelectedSession[0].sessionEndDateTime)) {
                  // Enable
                  this.courseList[i].courseSessions[j].disabled = false;
                } else {
                  // Disable
                  this.courseList[i].courseSessions[j].disabled = true;
                }
              } else {
                // Enable
                this.courseList[i].courseSessions[j].disabled = false;
              }
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.checkForChanges();
    }, 500);
  }

  async loginAPIService() {
    try {
      this.blockUI.start("Loading..."); // Start blocking

      const apiResponse = await this._apiService.login(Helper.encryptString("mbasha@cvc.com"), Helper.encryptString("m0Q7Y0000004PlJ"));
      if (apiResponse && apiResponse["token"]) {
        this.accessToken = apiResponse["token"];
        setTimeout(() => {
          this.blockUI.stop(); // Stop blocking
        }, 500);
        this.getAllEmployeeSessions(this.selectedEmployeeEmail, this.courseCategory);
      } else {
        this._router.navigate(["/error"]);
      }
    } catch (error) {
      console.log(error);
      // this._router.navigate(["/error"]);
      setTimeout(() => {
        this.blockUI.stop(); // Stop blocking
      }, 500);
    }
  }

  async getAllEmployeeSessions(email, courseCategory) {
    try {
      this.blockUI.start("Loading..."); // Start blocking
      this.initialLoadMessage = null;
      const reqObj = {
        EmployeeEmail: email,
        CourseName: courseCategory,
        isLoadMoreSession: true,
      };
      this.courseApiResponse = await this._apiService.getSessions(reqObj, this.accessToken);
      this.tempCourseApiResponse = cloneDeep(this.courseApiResponse);

      this.resetInitialCourseData();
      this.blockUI.stop(); // Stop blocking
      if (this.initialLoadMessage && this.isFirstLoading) {
        Swal.fire("Info", this.initialLoadMessage, "info");
        this.initialLoadMessage = null;
      }
      this.isFirstLoading = false;
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        this.blockUI.stop(); // Stop blocking
      }, 500);
      this.courseList = [];
      Swal.fire("Failed!", error, "error");
    }
  }

  resetInitialCourseData() {
    this.courseApiResponse = cloneDeep(this.tempCourseApiResponse);
    if (this.courseApiResponse) {
      this.courseList = this.courseApiResponse && this.courseApiResponse["courses"] && this.courseApiResponse["courses"].length > 0 ? this.courseApiResponse["courses"] : [];
      this.originalCourseList = cloneDeep(this.courseList);
      this.isAssistant = this.courseApiResponse && this.courseApiResponse["isAssistant"] ? this.courseApiResponse["isAssistant"] : false;
      if (this.isAssistant && this.loggedInUserEmail === this.selectedEmployeeEmail) {
        this.employeeList = this.courseApiResponse && this.courseApiResponse["assistantEmployees"] ? this.courseApiResponse["assistantEmployees"] : [];
      }
      this.selectedEmployeeId = this.courseApiResponse["employeeId"];
      if (this.courseList.length > 0) {
        let indexOfFirstCourse = 0;
        this.courseList = this.courseList.length > 0 ? this.courseList.sort((a, b) => a.courseLevel - b.courseLevel) : [];
        this.isCourseLevelEnabled = this.courseList.length > 0 && this.courseList[0].courseLevel ? true : false;
        this.isGroupEnabled = this.courseList.length > 0 && this.courseList[0].groupedCourse ? true : false;

        if (this.isGroupEnabled) this.isCourseLevelEnabled = false;

        // this.courseList = this.courseList.filter((course) => {
        //   return course.employeeCourseSession?.courseCompletionStatus !== "Completed";
        // });

        let previousBookedCourse = null;
        let previousBookedSession = null;
        let previousBookedCourseIndex = null;

        for (let i = 0; i < this.courseList.length; i++) {
          if (
            this.courseList[i].employeeCourseSession &&
            this.courseList[i].employeeCourseSession.employeeCourseSessionId &&
            this.courseList[i].employeeCourseSession.courseCompletionStatus === "Session Booked"
          ) {
            this.courseList[i].isAlreadyBookedCourse = true;
            this.courseList[i].isPutMeOnWaitingList = false;
            if (this.isGroupEnabled) this.isPutMeOnWaitingList = false;
          } else if (this.courseList[i].employeeCourseSession && this.courseList[i].employeeCourseSession.courseCompletionStatus === "Awaiting Session") {
            this.courseList[i].isPutMeOnWaitingList = true;
            if (this.isGroupEnabled) this.isPutMeOnWaitingList = true;
          } else {
            this.courseList[i].isPutMeOnWaitingList = false;
            this.courseList[i].isAlreadyBookedCourse = false;
            if (this.isGroupEnabled) this.isPutMeOnWaitingList = false;
          }

          if (this.courseList[i].courseLevel && this.courseList[i].courseLevel > 0) {
            this.courseList[i].isDateValidationEnabled = true;
          } else {
            this.courseList[i].isDateValidationEnabled = false;
          }

          this.courseList[i].courseSessions = this.courseList[i].courseSessions.map((item) => {
            const startdate = item.sessionStartDateTime.split("T")[0];
            const starttime = item.sessionStartDateTime.split("T")[1].slice(0, -1);
            const enddate = item.sessionEndDateTime.split("T")[0];
            const endtime = item.sessionEndDateTime.split("T")[1].slice(0, -1);

            const tempStartDate = moment(startdate, 'DD/MM/YYYY');
            const tempStartTime = starttime.split(':');
            const tempEndDate = moment(enddate, 'DD/MM/YYYY');
            const tempEndTime = endtime.split(':');

            const sessionStartDateTime = tempStartDate.set({
              hour: tempStartTime[0],
              minute: tempStartTime[1],
              second: tempStartTime[2],
            });

            const sessionEndDateTime = tempEndDate.set({
              hour: tempEndTime[0],
              minute: tempEndTime[1],
              second: tempEndTime[2],
            });

            const tempSTime = cloneDeep(sessionStartDateTime);
            const tempETime = cloneDeep(sessionEndDateTime);
            const tempCourseSessionName = `${item.group} - ${tempSTime.format('D-MMM-YYYY HH:mm')} - ${tempETime.format('HH:mm')}`;

            return {
              ...item,
              updatedCourseSessionName: tempCourseSessionName,
              sessionStartDateTime: sessionStartDateTime,
              sessionEndDateTime: sessionEndDateTime,
            };
          });
          this.courseList[i].courseSessions =
            this.courseList[i].courseSessions.length > 0 ? this.courseList[i].courseSessions.sort((a, b) => a.sessionStartDateTime.unix() - b.sessionStartDateTime.unix()) : [];

          // Updating current status message
          this.courseList[i].currentStatus = "Not Available";
          if (this.courseList[i].employeeCourseSession?.courseCompletionStatus === "Session Booked") this.courseList[i].currentStatus = "You have booked a session.";
          if (this.courseList[i].employeeCourseSession?.courseCompletionStatus === "Awaiting Session") this.courseList[i].currentStatus = "You have been placed on waiting list.";
          if (this.courseList[i].employeeCourseSession?.courseCompletionStatus === "Completed") this.courseList[i].currentStatus = "You have completed this course.";
          if (!this.courseList[i].employeeCourseSession) this.courseList[i].currentStatus = "You have not booked a session.";

          for (let j = 0; j < this.courseList[i].courseSessions.length; j++) {
            if (this.isCourseLevelEnabled && i > indexOfFirstCourse && !this.courseList[i].isAlreadyBookedCourse) {
              this.courseList[i].courseSessions[j].disabled = true;
            } else {
              this.courseList[i].courseSessions[j].disabled = false;
            }

            if (this.courseList[i].isAlreadyBookedCourse) {
              if (
                this.courseList[i].employeeCourseSession &&
                this.courseList[i].employeeCourseSession.employeeCourseSessionId &&
                this.courseList[i].employeeCourseSession.courseCompletionStatus !== "Awaiting Session" &&
                this.courseList[i].courseSessions[j].sessionID === this.courseList[i].employeeCourseSession.selectedCourseSessionId
              ) {
                previousBookedCourse = this.courseList[i];
                previousBookedSession = this.courseList[i].courseSessions[j];
                previousBookedCourseIndex = i;

                this.courseList[i].courseSessions[j].isSelected = true;
                this.courseList[i].courseSessions[j].isPreviouslySelected = true;
              } else {
                this.courseList[i].courseSessions[j].isSelected = false;
                this.courseList[i].courseSessions[j].isPreviouslySelected = false;
              }
            }

            if (previousBookedCourse && i - previousBookedCourseIndex === 1) {
              if (this.courseList[i].isDateValidationEnabled) {
                if (this.courseList[i].courseSessions[j].sessionStartDateTime.isSameOrAfter(previousBookedSession.sessionEndDateTime)) {
                  // Enable
                  this.courseList[i].courseSessions[j].disabled = false;
                } else {
                  // Disable
                  this.courseList[i].courseSessions[j].disabled = true;
                }
              } else {
                // Enable
                this.courseList[i].courseSessions[j].disabled = false;
              }
            }

            if ((this.isGroupEnabled && this.isPutMeOnWaitingList) || this.courseList[i].isPutMeOnWaitingList) {
              // Disable
              this.courseList[i].courseSessions[j].disabled = true;
            }
          }
        }
      }
      this.isCourseComplete = false;
      if (this.courseList.length === 1) {
        if (!this.courseList[0].employeeCourseSession) this.initialLoadMessage = null;
        if (this.courseList[0].employeeCourseSession?.courseCompletionStatus === "Awaiting Session")
          this.initialLoadMessage = "You have already placed yourself on waitlist for this course. If you wish to make changes to it please go ahead.";
        if (this.courseList[0].employeeCourseSession?.courseCompletionStatus === "Session Booked")
          this.initialLoadMessage = "You have already booked a session for this course. If you wish to make changes to it please go ahead.";
        if (this.courseList[0].employeeCourseSession?.courseCompletionStatus === "Completed") {
          this.initialLoadMessage = "You have already completed this course.";
          this.isCourseComplete = true;
        }
      } else if (this.courseList.length > 1 && this.isCourseLevelEnabled) {
        const tempCourse = cloneDeep(this.courseList);
        const selectedSessions = tempCourse.filter((s) => s.employeeCourseSession?.courseCompletionStatus === "Session Booked");
        const waitingListSessions = tempCourse.filter((s) => s.employeeCourseSession?.courseCompletionStatus === "Awaiting Session");
        const completedSessions = tempCourse.filter((s) => s.employeeCourseSession?.courseCompletionStatus === "Completed");
        if (selectedSessions.length === this.courseList.length)
          this.initialLoadMessage = "You have already booked sessions for all levels in this course. If you wish to make changes to it please go ahead.";
        if (selectedSessions.length !== this.courseList.length && waitingListSessions.length > 0)
          this.initialLoadMessage =
            "You have already booked sessions for certain levels and placed yourself on waitlist for a few levels in this course. Please proceed to book for the remaining levels in this course.";
        if (selectedSessions.length > 0 && selectedSessions.length !== this.courseList.length && waitingListSessions.length === 0)
          this.initialLoadMessage = "You have already booked sessions for certain levels in this course. Please proceed to book for the remaining levels in this course.";
        if (waitingListSessions.length === this.courseList.length)
          this.initialLoadMessage = "You have already placed yourself on waitlist for few levels in this course. Please proceed to book for the remaining levels.";
        if (completedSessions.length === this.courseList.length) {
          this.initialLoadMessage = "You have already completed all the levels of this course.";
          this.isCourseComplete = true;
        }
      }
      this.originalOutput = this.generateFinalOutput();
    }
  }

  generateFinalOutput() {
    const tempCourseList = cloneDeep(this.courseList);
    const output = {
      employeeId: this.selectedEmployeeId,
      courses: [],
    };
    for (const inputCourse of tempCourseList) {
      const selectedSessions = inputCourse.courseSessions.filter((s) => s.isSelected);
      if (selectedSessions.length > 0 && !selectedSessions[0].isPutMeOnWaitingList) {
        let outputObj = {};
        if (inputCourse.employeeCourseSession) {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: cloneDeep(inputCourse.employeeCourseSession),
            waitList: false,
          };
          outputObj["employeeCourseSession"]["selectedCourseSessionId"] = selectedSessions[0].sessionID;
        } else {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: {
              employeeCourseSessionId: "",
              selectedCourseSessionId: selectedSessions[0].sessionID,
              cancel: false,
              cancelledReason: "",
            },
            waitList: false,
          };
        }
        output.courses.push(outputObj);
      } else if (selectedSessions.length === 0 && !inputCourse.isPutMeOnWaitingList && inputCourse.employeeCourseSession?.selectedCourseSessionId) {
        let outputObj = {
          courseId: inputCourse.courseId,
          employeeCourseSession: cloneDeep(inputCourse.employeeCourseSession),
          waitList: false,
        };
        outputObj["employeeCourseSession"]["cancel"] = true;
        outputObj["employeeCourseSession"]["cancelledReason"] = inputCourse.cancelledReason;
        output.courses.push(outputObj);
      } else if (inputCourse.isPutMeOnWaitingList) {
        let outputObj = {};
        if (inputCourse.employeeCourseSession) {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: cloneDeep(inputCourse.employeeCourseSession),
            waitList: true,
          };
          outputObj["employeeCourseSession"]["selectedCourseSessionId"] = "";
        } else {
          outputObj = {
            courseId: inputCourse.courseId,
            employeeCourseSession: {
              employeeCourseSessionId: "",
              selectedCourseSessionId: "",
              cancel: false,
              cancelledReason: "",
            },
            waitList: true,
          };
        }
        output.courses.push(outputObj);
      }
    }
    return output;
  }

  checkForChanges() {
    const updatedOutput = this.generateFinalOutput();
    const changedOutput = this.removeDuplicates(this.originalOutput, updatedOutput);
    if (changedOutput.length > 0) {
      this.isChangeMade = true;
      this.showToast();
    } else {
      this.isChangeMade = false;
      this.removeToast();
    }
  }

  // Function to check if two objects are equal
  isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  // Function to remove duplicate objects from the courses array
  removeDuplicates(json1, json2) {
    const differences = json2.courses.filter((course2) => {
      return !json1.courses.some((course1) => this.isEqual(course1, course2));
    });
    return differences;
  }

  showToast = () => {
    if (!this.toastRef) {
      this.toastRef = this.toastr.show("Please click 'Book / Cancel' button at the top to save the changes.", null, {
        disableTimeOut: true,
        tapToDismiss: false,
        toastClass: "custom-toast-class ngx-toastr",
        closeButton: false,
        positionClass: "toast-bottom-right",
      });
    }
  };

  removeToast = () => {
    if (this.toastRef) {
      this.toastr.clear(this.toastRef.ToastId);
      this.toastRef = null;
    }
  };
}
