import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SessionDetailsComponent } from './components/pages/session-details/session-details.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ErrorComponent } from './components/pages/error/error.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'courses' },
    { path: 'courses', component: SessionDetailsComponent, canActivate: [OktaAuthGuard] },
    // { path: 'courses', component: SessionDetailsComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'callback', component: OktaCallbackComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }