<div class="error-area ptb-100">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="error-content">
          <img src="assets/img/error.png" alt="image" />
          <h3>Unexpected Error!</h3>
          <p>We are sorry, but it seems like an unexpected error has occurred. Please reach out to the administrator for more details. Thank you for your understanding.</p>
          <!-- <div class="btn-box">
            <a routerLink="/" class="default-btn"><i class="flaticon-history"></i>Go Back<span></span></a>
            <a routerLink="/" class="default-btn"><i class="flaticon-home"></i>Homepage<span></span></a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
