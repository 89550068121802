import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor() {
    }

    async ngOnInit(): Promise<void> {
        // get authentication state for immediate use
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}