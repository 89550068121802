<footer class="footer-area">
    <div class="container">
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-12" style="margin-bottom: 20px;">
                    <img src="assets/img/CVCLogoBlue.png" alt="logo">
                </div>
                <hr>
                <div class="col-12">
                    <p><i class='bx bx-copyright'></i>{{ currentYear }} CVC Capital Partners</p>
                </div>
                <!-- <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>