import * as CryptoJS from "crypto-js";

export class Helper {
  private static AESEncryptionString = "dSgVkYp3s6v9y/B?E(H+MbQeThWmZq4t";

  public static encryptString(inputString) {
    const iv = CryptoJS.enc.Utf8.parse(Helper.AESEncryptionString.substring(0,16));
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inputString), CryptoJS.enc.Utf8.parse(Helper.AESEncryptionString), {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    return encrypted.toString()
  }

  public static decryptString(inputString) {
    return CryptoJS.AES.decrypt(inputString.trim(), Helper.AESEncryptionString.trim()).toString(CryptoJS.enc.Utf8);
  }
}
