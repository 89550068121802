<div class="courses-area bg-white">
  <div class="container">
    <h3 class="L-D-Title">Learning and Development</h3>
    <div *ngIf="isGroupEnabled" class="row">
      <div class="col-lg-12">
        <div class="single-courses-item bg-light">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <div class="courses-content">
                <span class="session-time"><b>Note: &nbsp;</b>This course consists of more than one part and hence choose one session in each part</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="odemy-grid-sorting row align-items-center">
      <div class="col-lg-6 col-md-6 result-count">Session times are shown in your local time</div>
      <div class="col-lg-12 col-md-12 ordering float-right">
        <div class="select-box">
          <div *ngIf="isGroupEnabled && !isCourseComplete" class="display-inline">
            <h5 class="display-inline">Add to waiting list</h5>
            <ui-switch size="small" class="waiting-list-button" [(ngModel)]="isPutMeOnWaitingList" (valueChange)="onWaitingListToggleChange($event)">
              <i class="fa fa-check" *ngIf="isPutMeOnWaitingList"></i>
              <i class="fa fa-times" *ngIf="!isPutMeOnWaitingList"></i>
            </ui-switch>
          </div>
          <select *ngIf="employeeList.length !== 0" (change)="changeUser($event)" class="emp-list">
            <option *ngFor="let emp of employeeList" [value]="emp.email">{{ emp.employeeName }}</option>
          </select>
          <div class="btn-box ml-2 book-cancel-button">
            <a *ngIf="isChangeMade" class="default-btn" id="neonShadow" (click)="saveSessionDetails()">Book / Cancel<span></span></a>
            <a *ngIf="!isChangeMade" class="default-btn disabled-link" title="No changes in the sessions">Book / Cancel<span></span></a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="courseList && courseList.length > 0" class="courses-curriculum">
      <div *ngFor="let course of courseList; let i = index" class="mb-4">
        <div class="row">
          <div class="col-lg-8 col-md-8">
            <h4>{{ course["courseName"] }}</h4>
          </div>
          <div *ngIf="!isGroupEnabled && course.employeeCourseSession?.courseCompletionStatus !== 'Completed'" class="col-lg-4 col-md-4 mb-1 current-status">
            <h6 class="display-inline">Add to waiting list</h6>
            <ui-switch *ngIf="!isGroupEnabled" size="small" class="waiting-list-button mr-0" [(ngModel)]="course['isPutMeOnWaitingList']" (valueChange)="onSessionWaitingListToggleChange($event, i)">
              <i class="fa fa-check" *ngIf="course['isPutMeOnWaitingList']"></i>
              <!-- <i class="fa fa-times" *ngIf="!course['isPutMeOnWaitingList']"></i> -->
            </ui-switch>
          </div>
        </div>
        <div *ngIf="course && course['description']" class="row">
          <div class="col-lg-12 col-md-12 mb-2">
            <p>{{ course["description"] }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-2">
            <p class="current-status-box"><span class="current-status-text">Current Status:</span> {{ course["currentStatus"] }}</p>
          </div>
        </div>
        <div *ngIf="course['courseSessions'].length > 0" class="row">
          <div *ngFor="let session of course['courseSessions']; let j = index" class="col-lg-6 col-md-12">
            <div *ngIf="course['currentStatus'] !== 'You have completed this course.'" class="single-courses-item course-background">
              <div class="row align-items-center">
                <div class="col-lg-10 col-md-10">
                  <div class="courses-content">
                    <span class="session-title">{{session['updatedCourseSessionName']}}</span
                    >&nbsp;&nbsp;<span class="session-time"></span>
                    <ul class="courses-content-footer d-flex align-items-center mt-0">
                      <li><i class="fa fa-clock"></i>{{ session["duration"] }}</li>
                      <!-- <li><i class="flaticon-calendar"></i>{{ session["sessionStartDateTime"] | date: 'MMM d, y h:mm a' }} - {{ session["sessionEndDateTime"] | date: 'h:mm a' }}</li> -->
                      <!-- <li><i class="fa fa-location-dot"></i>{{ session["location"] }}</li> -->
                    </ul>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 float-right">
                  <span class="fav">
                    <ui-switch size="small" [disabled]="session['disabled']" [(ngModel)]="session['isSelected']" (valueChange)="onToggleChange(course, session)">
                      <i class="fa fa-check" *ngIf="session['isSelected']"></i>
                      <i class="fa fa-times" *ngIf="session['isCancelled']"></i>
                    </ui-switch>
                  </span>
                </div>
              </div>
              <div *ngIf="session['isCancelled'] && course['cancelledReason'] && course['cancelledReason'] !== ''" class="row">
                <p class="current-status-box bg-red"><span class="current-status-text">Cancelled Reason:</span> {{ course["cancelledReason"] }} <span class="edit-icon" (click)="cancelSession(i, j, course['cancelledReason'], true)"><i class="fa-solid fa-pen-to-square"></i></span></p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="course['courseSessions'].length === 0" class="row">
          <div class="col-lg-12 col-md-12 mb-2">
            <p class="current-status-box">No session available</p>
          </div>
        </div>
        <hr/>
      </div>
    </div>
  </div>
</div>
