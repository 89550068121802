import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiRouteConstants } from "../shared/constants/api.constants";
@Injectable({ providedIn: "root" })
export class APIService {
  constructor(private readonly httpClient: HttpClient) {}

  public login(username: string, password: string) {
    let uri = ApiRouteConstants.APIURLS.LOGIN;
    return this.httpClient
      .post(uri, {
        Username: username,
        Password: password,
      })
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  public getSessions(reqObj: any, authToken: string) {
    let uri = ApiRouteConstants.APIURLS.GETSESSIONS;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    });
    return this.httpClient
      .post(uri, reqObj, { headers: headers })
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  public updateSessions(reqObj: any, authToken: string) {
    let uri = ApiRouteConstants.APIURLS.UPDATESESSIONS;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    });
    return this.httpClient
      .post(uri, reqObj, { headers: headers })
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }
}
