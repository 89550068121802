<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets\img\CVCLogoWhite.png" alt="logo"></a>
            <!-- <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link" routerLink="/my-sessions" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"> My Sessions </a>
                    </li>
                    <li class="nav-item"><a class="nav-link" routerLink="/all-sessions" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"> All Sessions </a>
                    </li>
                    <li class="nav-item"><a class="nav-link" routerLink="/categories" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"> Categories </a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/my-sessions" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" class="nav-link"> My Sessions </a></li>
                    <li class="nav-item"><a routerLink="/all-sessions" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" class="nav-link"> All Sessions </a></li>
                    <li class="nav-item"><a routerLink="/categories" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" class="nav-link"> Categories </a></li>
                </ul>
            </div> -->
        </nav>
    </div>
</div>