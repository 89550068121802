/* eslint-disable @typescript-eslint/naming-convention */
export class ApiRouteConstants {
  // API Base
  public static API_BASE_PATH = 'api/LD';

  // API URLS
  public static APIURLS = {
    LOGIN: `${this.API_BASE_PATH}/Login`,
    GETSESSIONS: `${this.API_BASE_PATH}/GetCategoryCourseDetails`,
    UPDATESESSIONS: `${this.API_BASE_PATH}/BookORCancelCourseSession`,
  };
  
}
